import * as React from 'react';

import Layout from '~components/layout';
import Seo from '~components/seo';
import NotFound from '~containers/not-found';

const NotFoundPage = () => (
  <Layout>
    <Seo title='Not found' />
    <NotFound />
  </Layout>
);

export default NotFoundPage;
